<mat-form-field appearance="outline">
  <mat-label>Search Employee:</mat-label>
  <input matInput placeholder="type to search..." [matAutocomplete]="auto" [(ngModel)]="selectedEmp"
    [formControl]="searchEmpCtrl">
  <mat-icon matSuffix svgIcon="icon-search-solid-blue"
  matTooltip="Search icon" matTooltipPosition="above"></mat-icon>
  <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onSelected()"
    [displayWith]="displayFn">
    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let emp of filteredEmps" [value]="emp">
        <span><b>{{emp.full_name}}</b></span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <mat-error *ngIf="">Error</mat-error>
</mat-form-field>
