import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  AccountingCodes,
  ProjectAccountingAssignment,
} from 'src/app/models/accounting-codes';
import { ApiService } from 'src/app/services/api/api.service';
import { Assignment } from 'src/app/models/assignment';
import { SnackbarNotificationService } from 'src/app/services/snackbar-notification.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-accounting-codes',
  templateUrl: './accounting-codes.component.html',
  styleUrls: ['./accounting-codes.component.scss'],
})
export class AccountingCodesComponent implements OnInit {
  displayedColumns: string[] = [
    'source_labor_task_id',
    'source_labor_subtask_id',
    'account',
    'operating_unit',
    'product',
    'project',
    'process',
    'activity',
    'acct_type',
    'percentage',
  ];

  displayedColumns_all: string[] = [
    'select',
    'source_labor_task_id',
    'source_labor_subtask_id',
    'account',
    'operating_unit',
    'product',
    'project',
    'process',
    'activity',
    'acct_type',
  ];

  @Input() parentData: any;
  @Input() pageName: string; // input coming in from project or worker page
  @Input() toggleViewOption: boolean;
  @Input() editAcctMode: boolean;
  @Input() id: string;
  @Output() acctCodeUpdate: EventEmitter<any> = new EventEmitter();
  @Input() canEdit: boolean;
  public accountingCodes =
    new MatTableDataSource<ProjectAccountingAssignment>();
  public searchResults = new MatTableDataSource<AccountingCodes>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchFilter: FormGroup;
  showSearchResults: boolean = false;
  showCodes: boolean = true;
  projects: Assignment[] = [];
  codes_no_dup: ProjectAccountingAssignment[] = [];
  selectAcctForm: FormGroup;
  searchForm: FormGroup;
  isUpdate: boolean = false;
  all_codes: AccountingCodes[] = [];
  assigned_codes: ProjectAccountingAssignment[] = [];
  showAccountingCodes: boolean = false;
  all_codes_ds = new MatTableDataSource<AccountingCodes>();
  projects_no_dup: Assignment[] = [];
  buttonText: string = 'Edit';
  buttonToolTip: string = 'Edit Accounting Codes';
  project_name: string;
  accountingLoading: boolean = true;

  constructor(
    private repoService: ApiService,
    private snack: SnackbarNotificationService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    if (this.pageName == 'project') {
      this.getAllCodesAssignment();
    }

    if (!this.toggleViewOption) {
      this.showCodes = true;
    } else {
      this.showCodes = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.check &&
      changes.editAcctMode.previousValue &&
      !this.editAcctMode
    )
      this.disableForm();
  }
  //----------------------------------------------------
  // Create Accounting Checkbox Form
  private initForm() {
    this.selectAcctForm = this.fb.group({
      task: this.fb.control('', [Validators.required]),
      subtask: this.fb.control('', [Validators.required]),
      account: this.fb.control('', [Validators.required]),
      operUnit: this.fb.control('', [Validators.required]),
      product: this.fb.control('', [Validators.required]),
      project: this.fb.control('', Validators.required),
      process: this.fb.control('', Validators.required),
      activity: this.fb.control('', Validators.required),
      acct_type: this.fb.control('', Validators.required),
      percentage: this.fb.control('', Validators.required),
    });
    this.disableBoxes();

    this.searchForm = this.fb.group({
      code_search: this.fb.control(''),
    });
  }
  //----------------------------------------------------
  // Disable Accounting Checkboxes
  public disableBoxes() {
    this.selectAcctForm.controls['task'].disable();
    this.selectAcctForm.controls['subtask'].disable();
    this.selectAcctForm.controls['account'].disable();
    this.selectAcctForm.controls['operUnit'].disable();
    this.selectAcctForm.controls['product'].disable();
    this.selectAcctForm.controls['project'].disable();
    this.selectAcctForm.controls['process'].disable();
    this.selectAcctForm.controls['activity'].disable();
    this.selectAcctForm.controls['acct_type'].disable();
    this.selectAcctForm.controls['percentage'].disable();
  }

  ngAfterViewInit(): void {
    this.searchResults.paginator = this.paginator;
  }

  private getAllCodesAssignment() {
    let data = {};

    this.accountingLoading = true;

    data = {
      id: this.id,
      type: this.pageName,
    };

    let data1 = {};
    if (this.pageName == 'worker') {
      data1 = { source_worker_id: this.id };
    } else {
      data1 = { project_id: this.id };
    }

    forkJoin([
      this.repoService.getItems('accounting-codes', data),
      this.repoService.getItems('project-assignment', data1),
    ]).subscribe((res) => {
      this.accountingCodes.data = res[0] as ProjectAccountingAssignment[];
      this.projects = res[1] as Assignment[];
      if (this.projects[0]) {
        this.project_name = this.projects[0].project_name;
      } else {
        this.project_name = null;
      }
      //this will hold the codes that have been assigned to the project
      this.assigned_codes = res[0] as ProjectAccountingAssignment[];

      this.codes_no_dup = Array.from(
        this.accountingCodes.data
          .reduce((m, t) => m.set(t.par_accounting_id, t), new Map())
          .values()
      );

      //currently you cannot assign a user to a project twice, so just need to
      //check the charging exceptions projects.
      let total_worker_allocation = 0;
      let a_projects: Assignment[] = [];

      this.createListForDistinctPrj(this.projects,a_projects);

      a_projects = Array.from(
        a_projects.reduce((m, t) => m.set(t.project_id, t), new Map()).values()
      );
      this.projects_no_dup = a_projects;

      //------------------------------------------------------

      this.getTotalWorkerAllocation(a_projects,total_worker_allocation);
      //----------------------------------------------------

      this.calculateAndDisplayValues(a_projects);

      this.accountingCodes.data = this.codes_no_dup;
      this.getAccountCodeSelection();
      this.accountingLoading = false;
    })
  }

  calculateAndDisplayValues(a_projects) {
    //---- calculate and display values-----------------
    for (let r of this.codes_no_dup) {
      let project = a_projects.filter((x) => x.project_id == r.project_id);

      if (this.pageName == 'worker') {
        this.calculateAndDisplayValuesforWorker(project,r)
      } else {
        try {
          if (Number(r.type_percent) < 1) {
            r.type_percent_number = Number(r.type_percent) * 100;
          } else {
            r.type_percent_number = Number(r.type_percent);
          }
        } catch (e) {}
      }
    }
  }

  calculateAndDisplayValuesforWorker(project,r) {
    try {
      if (Number(r.type_percent) < 1) {
        r.type_percent_number =
          Number(r.type_percent) *
          Number(project[0].total_worker_allocation);
      } else {
        r.type_percent_number =
          Number(r.type_percent) *
          0.001 *
          Number(project[0].total_worker_allocation);
      }
    } catch (e) {}
  }

  getTotalWorkerAllocation(a_projects,total_worker_allocation) {
    //----get the total worker allocation values for each project--------
    let project_work_values: Assignment[] = [];
    for (let prj of a_projects) {
      let recs = this.projects.filter(
        (x) =>
          x.project_id == prj.project_id ||
          x.charging_project_id == prj.project_id
      );
      for (let r of recs) {
        total_worker_allocation =
          total_worker_allocation + Number(r.worker_time_allocation);
      }
      prj.total_worker_allocation = total_worker_allocation;
      total_worker_allocation = 0;
    }
  }

  createListForDistinctPrj(projects,a_projects) {
    //---create a list with the distinct project id's in it-----------
    for (let prj of projects) {
      a_projects.push({ project_id: prj.project_id });
      if (prj.charging_project_id) {
        a_projects.push({ project_id: prj.charging_project_id });
      }
    }
  }

  public doFilter_code = (event) => {
    try {
      this.all_codes_ds.filter = event.target.value.trim().toLocaleLowerCase();
    } catch (error) {
      this.all_codes_ds.filter = '';
    }
  };

  async searchAccountingCodes(filter?: string) {
    const data = {
      filter_value: filter,
    };
    this.repoService.searchItems('accounting', data).subscribe((res) => {
      this.searchResults.data = res as AccountingCodes[];
      if (this.searchResults) {
        this.searchResults.paginator = this.paginator;
      }
    });
  }

  public doFilter = (value: string) => {
    const filterVal: string = value.trim().toUpperCase();
    if (filterVal) {
      this.showSearchResults = true;
      this.searchAccountingCodes(filterVal);
    }
  };
  //----------------------------------------------------
  // Show Checkbox IF True
  public saveCodes(acct: AccountingCodes) {
    let task = false;
    let subtask = false;
    let account = false;
    let operUnit = false;
    let product = false;
    let project = false;
    let process = false;
    let activity = false;
    let acct_type = false;
    let percentage = false;

    if (this.selectAcctForm.controls['task'].value == true) {
      task = true;
    }
    if (this.selectAcctForm.controls['subtask'].value == true) {
      subtask = true;
    }
    if (this.selectAcctForm.controls['account'].value == true) {
      account = true;
    }
    if (this.selectAcctForm.controls['operUnit'].value == true) {
      operUnit = true;
    }
    if (this.selectAcctForm.controls['product'].value == true) {
      product = true;
    }
    if (this.selectAcctForm.controls['project'].value == true) {
      project = true;
    }
    if (this.selectAcctForm.controls['process'].value == true) {
      process = true;
    }
    if (this.selectAcctForm.controls['activity'].value == true) {
      activity = true;
    }
    if (this.selectAcctForm.controls['acct_type'].value == true) {
      acct_type = true;
    }
    if (this.selectAcctForm.controls['percentage'].value == true) {
      percentage = true;
    }

    let data = {
      type: 'update_accounting_selection',
      task: task,
      subtask: subtask,
      account: account,
      operUnit: operUnit,
      product: product,
      project: project,
      process: process,
      activity: activity,
      acct_type: acct_type,
      percentage: percentage,
      SK: 'PRJ#' + this.id,
      PK: 'ACCOUNTING_CODES_SELECTION',
      project_id: this.id,
    };
    this.repoService
      .updateItem('accounting-codes', data)
      .subscribe((res) => {});
    this.editAcctMode = !this.editAcctMode;
    this.disableBoxes();
  }

  getAccountCodeSelection() {
    let data = {
      type: 'select_account_code_box',
      project_id: this.id,
    };
    this.repoService.getItems('accounting-codes', data).subscribe((res) => {
      if (res[0]) {
        let results: any[] = [];
        results = res as any;

        let controls = ['task','subtask','account','operUnit','product','project','process','activity','acct_type','percentage']

        for (const iterator of controls) {
          if (results[0][iterator] === true) {
            this.selectAcctForm.controls[iterator].setValue(true);
          } else {
            this.selectAcctForm.controls[iterator].setValue(false);
          }
        }
      }
    });
  }

  toggleView() {
    this.showCodes = !this.showCodes;
    if (this.showCodes) {
      this.getAllCodesAssignment();
    }
  }

  async resetDelayed() {
    this.accountingLoading = true;
    let timeLeft: number;
    let interval;
    interval = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft--;
        if (timeLeft == 0) {
          clearInterval(interval);
          this.getAllCodesAssignment();
        }
      } else {
        timeLeft = 1;
      }
    }, 2000);
  }
  //----------------------------------------------------
  // Accounting Codes Checkbox Functions
  public editAcct() {
    this.editAcctMode = !this.editAcctMode;
    this.selectAcctForm.enable();
  }

  public cancelChanges() {
    this.editAcctMode = !this.editAcctMode;
    this.disableForm();
  }

  private disableForm(): void {
    this.selectAcctForm.disable();
  }

  showEditForm() {
    this.isUpdate = !this.isUpdate;
    if (this.isUpdate) {
      if (this.all_codes.length == 0) {
        this.accountingLoading = true;
        //load all codes
        let d = {
          type: 'all',
        };
        this.repoService.getItems('accounting-codes', d).subscribe((res) => {
          this.all_codes = res as AccountingCodes[];
          this.all_codes_ds.data = this.all_codes;
          this.accountingLoading = false;
        });
      }
      this.buttonText = 'Save';
      this.buttonToolTip = 'Save Accounting Codes';
    } else {
      this.resetDelayed();
      this.buttonText = 'Edit';
      this.buttonToolTip = 'Edit Accounting Codes';
      this.searchForm.controls['code_search'].setValue('');
      this.searchForm.controls['code_search'].setValue('');
      let e = new KeyboardEvent('keyup', { keyCode: 32 });
      this.doFilter_code(e);
    }
  }

  selectionChange(event, data) {
    data.project_id = this.id;
    data.project_name = this.project_name;

    if (event.checked) {
      //add to assigned_codes
      this.repoService
        .saveItems('accounting-codes-assignment', data)
        .subscribe((res) => {
          try {
            if (Number(data.type_percent) < 1) {
              data.type_percent_number = Number(data.type_percent) * 100;
            } else {
              data.type_percent_number = Number(data.type_percent);
            }
          } catch (e) {}
          this.assigned_codes.push(data);
        });
    } else {
      //remove from assigned_codes
      this.repoService
        .deleteItem('accounting-codes-assignment', data)
        .subscribe((res) => {
          this.assigned_codes = this.assigned_codes.filter(
            (item) => item !== data
          );
        });
    }
  }

  isSelected(data) {
    //if row is in assigned_codes
    let r = this.assigned_codes.filter(
      (x) => x.par_accounting_id == data.par_accounting_id
    );
    if (r.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  toggleTableHeight() {
    this.showAccountingCodes = !this.showAccountingCodes;
  }
}
