<div class="section-container">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <div class="de-h3" fxFlex="50%">Accounting Codes</div>

    <div *ngIf="pageName == 'project'" fxFlex="50%">
      <div fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="column" fxLayoutAlign="space-between center">
          <div fxFlex="10%" *ngIf="canEdit">
            <mat-icon
              class="icon-size-1x"
              svgIcon="pencil-icon"
              (click)="editAcct()"
              *ngIf="!editAcctMode"
              matTooltip="Select applicable accounting codes for this project"
              matTooltipPosition="above"
            ></mat-icon>
            <mat-icon
              class="icon-size-1x"
              svgIcon="x-icon-red"
              (click)="cancelChanges()"
              *ngIf="editAcctMode"
              matTooltip="Cancel changes"
              matTooltipPosition="above"
            ></mat-icon>
            <mat-icon
              class="icon-size-1x"
              svgIcon="check-icon-green"
              (click)="saveCodes(selectAcctForm.value)"
              *ngIf="editAcctMode"
              matTooltip="Save changes"
              matTooltipPosition="above"
            ></mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="spinner-container" *ngIf="accountingLoading">
    <div class="progress-spinner">
      <mat-progress-spinner
        mode="indeterminate"
        color="primary"
      ></mat-progress-spinner>
    </div>
  </div>
  <div
    *ngIf="!accountingLoading"
    class="table"
    [ngClass]="{ 'table-height': showAccountingCodes }"
  >
    <mat-table [dataSource]="accountingCodes" id="allocation_table">
      <form [formGroup]="selectAcctForm">
        <ng-container matColumnDef="source_labor_task_id">
          <!-- Task -->
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="codes_checkbox"
              formControlName="task"
              [disabled]="editAcctMode == false"
              *ngIf="pageName == 'project'"
            ></mat-checkbox
            >Task
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.source_labor_task_id }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="source_labor_subtask_id">
          <!-- Subtask -->
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="codes_checkbox"
              formControlName="subtask"
              [disabled]="false"
              *ngIf="pageName == 'project'"
            ></mat-checkbox
            >Subtask
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.source_labor_subtask_id }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="account">
          <!-- Account -->
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="codes_checkbox"
              formControlName="account"
              [disabled]="false"
              *ngIf="pageName == 'project'"
            ></mat-checkbox
            >Account
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.account }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="operating_unit">
          <!-- Oper. Unit -->
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="codes_checkbox"
              formControlName="operUnit"
              [disabled]="false"
              *ngIf="pageName == 'project'"
            ></mat-checkbox
            >Oper. Unit
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.operating_unit }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="product">
          <!-- Product -->
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="codes_checkbox"
              formControlName="product"
              [disabled]="false"
              *ngIf="pageName == 'project'"
            ></mat-checkbox
            >Product
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.product }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="project">
          <!-- Project -->
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="codes_checkbox"
              formControlName="project"
              [disabled]="false"
              *ngIf="pageName == 'project'"
            ></mat-checkbox
            >Project
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.project }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="process">
          <!-- Process -->
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="codes_checkbox"
              formControlName="process"
              [disabled]="false"
              *ngIf="pageName == 'project'"
            ></mat-checkbox
            >Process
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.process }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="activity">
          <!-- Activity -->
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="codes_checkbox"
              formControlName="activity"
              [disabled]="false"
              *ngIf="pageName == 'project'"
            ></mat-checkbox
            >Activity
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.activity }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acct_type">
          <!-- Acct. Type -->
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="codes_checkbox"
              formControlName="acct_type"
              [disabled]="false"
              *ngIf="pageName == 'project'"
            ></mat-checkbox
            >Acct. Type
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.acct_type }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="percentage">
          <!-- Percentage -->
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="codes_checkbox"
              formControlName="percentage"
              [disabled]="false"
              *ngIf="pageName == 'project'"
            ></mat-checkbox
            >Percentage (%)
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.type_percent_number | number : "0.0-2" }} %</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row
          matRipple
          *matRowDef="let row; let even = even; columns: displayedColumns"
          class="table-row"
          tabindex="1"
          [ngClass]="{ gray: even }"
        ></mat-row>
      </form>
    </mat-table>
  </div>

  <div class="codes-filter" *ngIf="pageName === 'set_this_to_worker'">
    <mat-form-field appearance="outline" fxFlex="25%">
      <input
        #filterInput
        matInput
        type="text"
        id="filter-acc-codes"
        aria-labelledby="filter-acc-codes"
        (keyup.enter)="doFilter(filterInput.value)"
        placeholder="Type to filter accounting codes..."
      />
      <mat-icon
        matSuffix
        svgIcon="icon-search-solid-blue"
        (click)="doFilter(filterInput.value)"
        matTooltip="Search icon"
        matTooltipPosition="above"
      ></mat-icon>
    </mat-form-field>
  </div>
  <div *ngIf="showSearchResults && pageName === 'worker'">
    <h4>Search Results:</h4>
    <mat-table [dataSource]="searchResults" class="mat-elevation-z8">
      <ng-container matColumnDef="source_labor_task_id">
        <mat-cell *matCellDef="let element">
          {{ element.source_labor_task_id }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="source_labor_subtask_id">
        <mat-cell *matCellDef="let element">
          {{ element.source_labor_subtask_id }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="account">
        <mat-cell *matCellDef="let element"> {{ element.account }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="operating_unit">
        <mat-cell *matCellDef="let element">
          {{ element.operating_unit }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="product">
        <mat-cell *matCellDef="let element"> {{ element.product }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="project">
        <mat-cell *matCellDef="let element"> {{ element.project }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="process">
        <mat-cell *matCellDef="let element"> {{ element.process }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="activity">
        <mat-cell *matCellDef="let element"> {{ element.activity }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acct_type">
        <mat-cell *matCellDef="let element"> {{ element.acct_type }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="percentage">
        <mat-cell *matCellDef="let element">
          {{ element.percentage }} %</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-cell *matCellDef="let element">
          <de-button
            (click)="saveCodes(element)"
            matTooltip="Select Codes"
            matTooltipPosition="above"
            >select</de-button
          >
        </mat-cell>
      </ng-container>

      <mat-row
        matRipple
        *matRowDef="let row; let even = even; columns: displayedColumns"
        class="table-row"
        tabindex="1"
        [ngClass]="{ gray: even }"
      ></mat-row>
    </mat-table>
    <mat-paginator
      showFirstLastButtons="true"
      [pageSize]="5"
      [pageSizeOptions]="[2, 4, 6]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
  <div fxLayoutAlign="right" *ngIf="canEdit">
    <de-button
      matTooltip="{{ buttonToolTip }}"
      matTooltipPosition="above"
      primary
      type="primary"
      (click)="showEditForm()"
      >{{ buttonText }}</de-button
    >

    <div>
      <de-button
        primary
        type="primary"
        *ngIf="!showAccountingCodes"
        (click)="toggleTableHeight()"
        matTooltip="Expand View of Accounting Codes Table"
        matTooltipPosition="above"
        >Expand View</de-button
      >
    </div>
    <div>
      <de-button
        primary
        type="primary"
        *ngIf="showAccountingCodes"
        (click)="toggleTableHeight()"
        matTooltip="Reduce View of Accounting Codes Table"
        matTooltipPosition="above"
        >Reduce View</de-button
      >
    </div>
  </div>
  <div *ngIf="isUpdate" class="search_form">
    <form [formGroup]="searchForm" class="project-header-search">
      <mat-form-field appearance="outline" class="filter-input">
        <input
          matInput
          formControlName="code_search"
          type="text"
          id="search-form"
          aria-labelledby="search-form"
          (keyup)="doFilter_code($event)"
          placeholder="Type to filter ..."
        />
      </mat-form-field>
    </form>
  </div>
  <div *ngIf="isUpdate">
    <div class="table-container">
      <div class="table">
        <mat-table [dataSource]="all_codes_ds" id="allocation_table">
          <ng-container matColumnDef="select">
            <!-- Task -->
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-checkbox
                (change)="selectionChange($event, element)"
                [checked]="isSelected(element)"
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="source_labor_task_id">
            <!-- Task -->
            <mat-header-cell *matHeaderCellDef> Task </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.source_labor_task_id }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="source_labor_subtask_id">
            <!-- Subtask -->
            <mat-header-cell *matHeaderCellDef> Subtask </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.source_labor_subtask_id }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="account">
            <!-- Account -->
            <mat-header-cell *matHeaderCellDef> Account </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.account }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="operating_unit">
            <!-- Oper. Unit -->
            <mat-header-cell *matHeaderCellDef> Oper. Unit </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.operating_unit }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="product">
            <!-- Product -->
            <mat-header-cell *matHeaderCellDef> Product </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.product }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="project">
            <!-- Project -->
            <mat-header-cell *matHeaderCellDef> Project </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.project }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="process">
            <!-- Process -->
            <mat-header-cell *matHeaderCellDef> Process </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.process }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="activity">
            <!-- Activity -->
            <mat-header-cell *matHeaderCellDef> Activity </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.activity }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="acct_type">
            <!-- Acct. Type -->
            <mat-header-cell *matHeaderCellDef> Acct. Type </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.acct_type }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element"> </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns_all; sticky: true"
          ></mat-header-row>
          <mat-row
            matRipple
            *matRowDef="let row; let even = even; columns: displayedColumns_all"
            class="table-row"
            tabindex="1"
            [ngClass]="{ gray: even }"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
