import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarNotificationService {

  constructor(private snackBar:MatSnackBar) { }
  openSuccessSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
         duration: 3000,
         horizontalPosition: 'center',
         verticalPosition: 'top',
         panelClass: ['green-snackbar'],
      });
    }

    openErrorSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
         duration: 3000,
         horizontalPosition: 'center',
         verticalPosition: 'top',
         panelClass: ['red-snackbar'],
      });
    }
  }
