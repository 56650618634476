<div class="dialog-container">
  <mat-card class="header-card de-h3" fxLayout="row"
    >PAR Notifications
  </mat-card>
  <div class="notification-container">
    <div class="manager-display-name">
      <span>Showing Notifications For: </span>
      <span class="name-manager">{{ currentManagerName }}</span>
    </div>

    <div class="display-notifications">
      <div class="spinner-container" *ngIf="notificationLoading">
        <div class="progress-spinner">
          <mat-progress-spinner
            mode="indeterminate"
            color="primary"
          ></mat-progress-spinner>
        </div>
      </div>
      <div *ngIf="!notificationLoading" class="notification-list">
        <mat-nav-list>
          <div class="list">
            <!-- No Notifications -->
            <div class="items">
              <mat-list-item
                *ngIf="noNotification"
                (click)="closeDialog()"
                matTooltip="Close Dialog"
                matTooltipPosition="above"
              >
                <div class="left-align-list">
                  <mat-icon class="icon-size" svgIcon="alert-base"></mat-icon>
                  <h3 class="notification-text" matListItemTitle>
                    <div>No New Notifications At This Time.</div>
                  </h3>
                </div>
              </mat-list-item>
            </div>

            <!-- Unallocated Workers Notification -->
            <div class="items" *ngIf="unallocatedNotification">
              <mat-list-item
                (click)="closeDialog()"
                [routerLink]="'/worker/dashboard/'"
                [queryParams]="{ worker_type: 'unassigned' }"
                matTooltip="Navigate To Source"
                matTooltipPosition="above"
              >
                <div class="left-align-list">
                  <mat-icon class="icon-size" svgIcon="alert-base"></mat-icon>
                  <h3 class="notification-text" matListItemTitle>
                    <div>You have workers that are unallocated.</div>
                  </h3>
                </div>
              </mat-list-item>
              <div class="clear-notification">
                <button
                  (click)="clearUnallocated()"
                  matTooltip="Clear Notification"
                  matTooltipPosition="above"
                >
                  Clear
                </button>
              </div>
            </div>
            <!-- Incorrect Charge Notification -->
            <div class="items" *ngIf="chargingNotification">
              <mat-list-item
                (click)="closeDialog()"
                [routerLink]="'/reports/cap-om-report'"
                matTooltip="Navigate To Source"
                matTooltipPosition="above"
              >
                <div class="left-align-list">
                  <mat-icon class="icon-size" svgIcon="alert-base"></mat-icon>
                  <h3 class="notification-text" matListItemTitle>
                    <div>You have workers charging to incorrect codes.</div>
                  </h3>
                </div>
              </mat-list-item>
              <div class="clear-notification">
                <button
                  (click)="clearCharging()"
                  matTooltip="Clear Notification"
                  matTooltipPosition="above"
                >
                  Clear
                </button>
              </div>
            </div>

            <!-- Over Tolerance On CAP/OM Variance -->
            <div class="items" *ngIf="varianceNotification">
              <mat-list-item
                (click)="closeDialog()"
                [routerLink]="'/reports/cap-om-report'"
                matTooltip="Navigate To Source"
                matTooltipPosition="above"
              >
                <div class="left-align-list">
                  <mat-icon class="icon-size" svgIcon="alert-base"></mat-icon>
                  <h3 class="notification-text" matListItemTitle>
                    <div>You have workers with high CAP/OM Variance.</div>
                  </h3>
                </div>
              </mat-list-item>
              <div class="clear-notification">
                <button
                  (click)="clearVariance()"
                  matTooltip="Clear Notification"
                  matTooltipPosition="above"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </mat-nav-list>
      </div>
    </div>
    <div class="close-dialog">
      <button
        (click)="closeDialog()"
        matTooltip="Close Dialog"
        matTooltipPosition="above"
      >
        Close
      </button>
    </div>
  </div>
</div>
