<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <div class="de-h3" *ngIf="parentName == 'app-worker-details'" fxFlex="50%">
    Accounting Codes:
     <!-- WE'LL LOAD THIS ICON, IF THE USER HAVEN'T SELECTED ANY VALUES FROM THE PRODUCT DETAILS TAB-->
    <mat-icon 
      class="icon"
      *ngIf="emptyValuesTooltip" 
      svgIcon="icon-information"
      matTooltip="MyTime Accounting Codes have not been Selected on Product Page"
      matTooltipPosition="above"
    >
    </mat-icon>
  </div>
</div>

<div *ngIf="parentName == 'app-worker-details'">
  <de-button
    primary
    type="primary"
    *ngIf="!showCodes"
    (click)="toggleView()"
    matTooltip="Open Accounting Codes"
    matTooltipPosition="above"
    >View Acct. Codes</de-button
  >
</div>

<div class="section-container" *ngIf="showCodes">
  <div *ngIf="showCodes">
    <div class="progress-spinner" *ngIf="accountingCodesLoading">
      <mat-progress-spinner
        mode="indeterminate"
        color="primary"
      ></mat-progress-spinner>
    </div>
    <mat-table
      class="table"
      *ngIf="!accountingCodesLoading"
      [dataSource]="accountingCodes"
      id="allocation_table"
    >
      <ng-container matColumnDef="project_name">
        <!-- Product Name -->
        <mat-header-cell *matHeaderCellDef> Product Name </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="table-align">
            <a
              class="internal-link"
              [routerLink]="'/project/' + element.project_id"
              matTooltip="View Project detail"
            >
              {{ element.project_name }}</a
            >
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="source_labor_task_id">
        <!-- Task -->
        <mat-header-cell *matHeaderCellDef> Task </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.source_labor_task_id }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="source_labor_subtask_id">
        <!-- Subtask -->
        <mat-header-cell *matHeaderCellDef> Subtask </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.source_labor_subtask_id }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="account">
        <!-- Account -->
        <mat-header-cell *matHeaderCellDef> Account </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.account }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="operating_unit">
        <!-- Oper. Unit -->
        <mat-header-cell *matHeaderCellDef> Oper. Unit </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.operating_unit }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="product">
        <!-- Product -->
        <mat-header-cell *matHeaderCellDef> Product </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.product }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="project">
        <!-- Project -->
        <mat-header-cell *matHeaderCellDef> Project </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.project }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="process">
        <!-- Process -->
        <mat-header-cell *matHeaderCellDef> Process </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.process }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="activity">
        <!-- Activity -->
        <mat-header-cell *matHeaderCellDef> Activity </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.activity }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="percentage">
        <!-- Percentage -->
        <mat-header-cell *matHeaderCellDef>Percentage (%)</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.percentage | number : "0.0-2" }} %</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element"> </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        matRipple
        *matRowDef="let row; let even = even; columns: displayedColumns"
        class="table-row"
        tabindex="1"
        [ngClass]="{ gray: even }"
      ></mat-row>
    </mat-table>
  </div>
</div>

<div *ngIf="parentName == 'app-worker-details'">
  <de-button
    primary
    type="primary"
    *ngIf="showCodes"
    (click)="toggleView()"
    matTooltip="Collapse Accounting Codes"
    matTooltipPosition="above"
    >Collapse View</de-button
  >
</div>
