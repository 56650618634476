import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  AccountingCodes,
  ProjectAccountingAssignment,
} from 'src/app/models/accounting-codes';
import { ApiService } from 'src/app/services/api/api.service';
import { Assignment } from 'src/app/models/assignment';
import { SnackbarNotificationService } from 'src/app/services/snackbar-notification.service';
import { forkJoin } from 'rxjs';
import { AccountingCodesComponent } from '../accounting-codes/accounting-codes.component';
import { Router } from '@angular/router';
import { Project } from 'src/app/models/project';
import { WorkerEmailUpdateComponent } from 'src/app/modules/worker/components/worker-details/worker-email-update/worker-email-update.component';

@Component({
  selector: 'app-display-accounting-codes',
  templateUrl: './display-accounting-codes.component.html',
  styleUrls: ['./display-accounting-codes.component.scss'],
})
export class DisplayAccountingCodesComponent implements OnInit {
  displayedColumns: string[] = [
    'project_name',
    'source_labor_task_id',
    'source_labor_subtask_id',
    'account',
    'operating_unit',
    'project',
    'product',
    'process',
    'activity',
    'percentage',
  ];

  @Input() pageName: string; // input coming in from project or worker page
  @Input() toggleViewOption: boolean;
  @Input() parentData: boolean;
  @Input() id: string;
  @Output() acctCodeUpdate: EventEmitter<any> = new EventEmitter();
  @Input() parentName: string;

  public accountingCodes =
    new MatTableDataSource<ProjectAccountingAssignment>();
  public searchResults = new MatTableDataSource<AccountingCodes>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchFilter: FormGroup;
  showSearchResults: boolean = false;
  showCodes: boolean = true;
  projectsList: Project[];
  projects: Assignment[] = [];
  codes_no_dup: ProjectAccountingAssignment[] = [];
  accountingCodesCheck: AccountingCodesComponent;
  selections: any[] = [];
  genPreview: WorkerEmailUpdateComponent;
  accountingCodesLoading: boolean = true;
  a_projects: Assignment[] = [];
  emptyValuesTooltip: boolean = false;

  constructor(
    private repoService: ApiService,
    private snack: SnackbarNotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (
      this.pageName == 'project' ||
      this.parentName == 'app-email-verification'
    ) {
      this.getAllCodesAssignment();
    }

    if (!this.toggleViewOption || this.parentName == 'app-email-verification') {
      this.showCodes = true;
    } else {
      this.showCodes = false;
    }
  }

  ngAfterViewInit(): void {
    this.searchResults.paginator = this.paginator;
  }

  distributeProjects(){
      //---create a list with the distinct project id's in it-----------
      for (let prj of this.projects) {
        if(prj.charging_project_id){
          this.a_projects.push({project_name: prj.charging_project_name, project_id: prj.charging_project_id})
        }
        else{
          this.a_projects.push({project_name: prj.project_name, project_id: prj.project_id})
        }
      }
  }

  async getAllCodesAssignment() {
    let data = {};

    data = {
      id: this.id,
      type: this.pageName,
    };
    let data1 = {};
    if (this.pageName == 'worker') {
      data1 = { source_worker_id: this.id };
    } else {
      data1 = { project_id: this.id };
    }

    forkJoin([
      this.repoService.getItems('accounting-codes', data),
      this.repoService.getItems('project-assignment', data1),
    ]).subscribe(async (res) => {
      this.accountingCodes.data = res[0] as ProjectAccountingAssignment[];
      this.projects = res[1] as Assignment[];
      this.codes_no_dup = Array.from(
        this.accountingCodes.data
        // .reduce((m, t) => m.set(t.par_accounting_id, t) && m.set(t.percentage, t), new Map())
        // .values()
      );

      //currently you cannot assign a user to a project twice, so just need to
      //check the charging exceptions projects.
      let total_worker_allocation = 0;
      this.distributeProjects();
      //------------------------------------------------------
      await this.totalworkerallocation();

      this.accountingCodesLoading = false;
    },
    (err) => {
        // stop loading, since this API called failed already
        this.accountingCodesLoading = false;
        Error('API call unsuccessfull due to some reasons. Might be missing codes? Please retry.');
    });
  }

  async totalworkerallocation() {
    let total_worker_allocation = 0;
    //----get the total worker allocation values for each project--------
    for (let prj of this.a_projects) {
      let recs = this.projects.filter(
        (x) =>
          x.project_id == prj.project_id ||
          x.charging_project_id == prj.project_id
      );
      for (let r of recs) {
        total_worker_allocation =
          total_worker_allocation + Number(r.worker_time_allocation);
      }
      prj.total_worker_allocation = total_worker_allocation;
      total_worker_allocation = 0;
    }

    this.accountingCodes.data = this.codes_no_dup;

    for (let r of this.a_projects) {
      let data2 = {
        type: 'select_account_code_box',
        project_id: r.project_id,
        project_name: r.project_name,
      };
      // calling the api to filter values that shouldn't be shown on the UI(we decide it using the
      // ticks used on project's ACCOUNTING CODES section)
      await this.accountingCodesCall(data2);
      this.toolTipForMissingRecords();
    }

    // getting wrong project_id from the backend so will fix this by iterating it with 'a_projects' (since those
    // have correct IDs) and replacing them on UI for perfect navigation from frontend
    for (let accountingData of this.accountingCodes.data) {
      let accData = this.a_projects.find(
        (projects) => projects.project_name == accountingData.project_name
      );
      if (accData) {
        accountingData.project_id = accData.project_id;
      }
    }
  }

  async searchAccountingCodes(filter?: string) {
    const data = {
      filter_value: filter,
    };
    this.repoService.searchItems('accounting', data).subscribe((res) => {
      this.searchResults.data = res as AccountingCodes[];
      if (this.searchResults) {
        this.searchResults.paginator = this.paginator;
      }
    });
  }

  public doFilter = (value: string) => {
    const filterVal: string = value.trim().toUpperCase();
    if (filterVal) {
      this.showSearchResults = true;
      this.searchAccountingCodes(filterVal);
    }
  };

  // Navigation
  public navigateToProject(id) {
    let url = `/project/${id}`;
    this.router.navigateByUrl(url);
  }

  toggleView() {
    this.showCodes = !this.showCodes;
    if (this.showCodes || this.parentName == 'app-email-verification') {
      this.getAllCodesAssignment();
    }
  }

  public resetDelayed() {
    let timeLeft: number;
    let interval;
    interval = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft--;
        if (timeLeft == 0) {
          clearInterval(interval);
          this.getAllCodesAssignment();
        }
      } else {
        timeLeft = 8;
      }
    }, 1000);
  }

  async accountingCodesCall(data2): Promise<string> {
    // Will clear out the values here that shouldn't be shown on the UI 'ACCOUNTING CODES' section
    return new Promise((resolve, reject) => {
      this.repoService
        .getItems('accounting-codes', data2)
        .subscribe(async (res) => {
          if (res[0]) {
            let records = [];
            for (let x of this.accountingCodes.data) {
              // instead of 'project_id' we'll use 'project_name' here to differentiate projects (since
              // projects_id(also .filter()) isn't working quite well currently)
              if (x.project_name == data2.project_name) {
                records.push(x);
              }
            }
            this.clearingValues(res,records);
            resolve('API call successfull');
          } else {
            // stop loading, since this API called failed already
            this.accountingCodesLoading = false;
            reject(Error('API call unsuccessfull due to some reasons. Might be missing codes? Please retry.'));
          }
        });
    });
  }

  clearingValues(res, records) {
    // clearing out the values
    for (let r of records) {
      if(!res[0].task)  r.source_labor_task_id = ''
      if(!res[0].subtask) r.source_labor_subtask_id = '';
      if (!res[0].operUnit) r.operating_unit = '';
      if (!res[0].percentage) r.type_percent = null;

      for (let iterator of ['account','product','project','process','activity']) {
        if(!res[0][iterator]) r[iterator] = '';
      }
    }
  }

  toolTipForMissingRecords() {
    let records = ['source_labor_task_id', 'source_labor_subtask_id', 'operating_unit', 'type_percent', 
                      'account','product','project','process','activity']
    // adding the tooltip if any of the accounting codes has all of the values missing
    for(let codes of this.accountingCodes.data) {
      let emptyValues = true
      for(let value of records) {
        if(codes[value]) emptyValues = false;
      }
      if(emptyValues) { this.emptyValuesTooltip = true; break; };
    }
  }
}
