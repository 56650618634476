import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { filter, distinctUntilChanged, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { WorkerInfo } from 'src/app/models/worker-info';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss']
})
export class EmployeeSearchComponent implements OnInit {

  searchEmpCtrl = new FormControl();
  filteredEmps: WorkerInfo[];
  isLoading = false;
  errorMsg!: string;
  minCharsLength = 3;
  selectedEmp: any = "";

  @Input() selectedEmployee;
  @Output() selEmpChanged: EventEmitter<any> =   new EventEmitter();

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { }

  onSelected() {
    this.selEmpChanged.emit(this.selectedEmp);
  }

  displayFn(value: any) {
    return value?.full_name;
  }

  clearSelection() {
    this.selectedEmp = "";
    this.filteredEmps = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.selectedEmployee.isFirstChange) this.selectedEmp = this.selectedEmployee;
  }

  ngOnInit() {
    this.searchEmpCtrl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minCharsLength
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.errorMsg = "";
          this.filteredEmps = [];
          this.isLoading = true;
        }),
        switchMap(value => this.api.searchItems('worker', {'filter_value': value.toLowerCase() })
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (data == undefined) {
          this.filteredEmps = [];
        } else {
          this.errorMsg = "";
          this.filteredEmps = data as WorkerInfo[];
        }
      });
  }
}
